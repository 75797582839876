<template width="400" max-height="300">
  <vue-perfect-scrollbar
    class="chat-room--scrollbar"
    v-bind:style="editListStyle"
    ref="vuePerfectScroll"
  >
    <v-list dense>
      <v-list-item-group
        v-model="selectedEdit"
        :class="
          `${
            !isEditsListVisible && EditName !== null
              ? 'listHeightShort'
              : 'listHeightLong'
          }`
        "
      >
        <v-list-item
          v-for="(item, i) in editNameList"
          :key="i"
          id="listItemHeight"
        >
          <v-list-item-content @click="changeSelectedEdit(i)">
            <v-list-item-title
              v-if="item.id === editName"
              class="titleHighlight"
              >{{ item.name }}</v-list-item-title
            >
            <v-list-item-title v-else>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </vue-perfect-scrollbar>
</template>

<script>
import { mapState } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "editList",
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      selectedEdit: this.editName !== (null || undefined) ? 0 : -1,
      editListStyle: { "min-height": "10px", "max-height": "150px" }
    };
  },
  computed: {
    ...mapState({
      editName: state => state.edit.currentSelectedEdit.id,
      isFilterMenu: state => state.edit.filterMenu,
      editNameList: state => state.edit.editNameList,
      isEditsListVisible: state => state.edit.isEditsListVisible,
      geolocateMode: state => state.geolocation.geolocate
    })
  },
  mounted() {
    this.changeSelectedEdit();
  },
  methods: {
    changeSelectedEdit(selectedEditId) {
      if (selectedEditId !== undefined) {
        this.editNameList.filter((name, index) => {
          if (selectedEditId === index && this.editName !== name.id) {
            if (this.geolocateMode === "follow") {
              this.$store.commit("geolocation/changeGeolocate", "not-follow");
            }
            this.$insProgress.start();
            this.$store.dispatch("edit/handleSelectedEditName", {
              index: index,
              name: name.name,
              id: name.id
            });
            setTimeout(() => {
              this.selectedEdit = 0;
            }, 0);
          } else if (this.editName === name.id) {
            this.$store.dispatch("edit/handleRemoveEditFromMap");
            setTimeout(() => {
              this.selectedEdit = null;
            }, 0);
          }
          let scrollBar = this.$refs.vuePerfectScroll;
          scrollBar.ps.scrollbarX.hidden = true;
          scrollBar.$el.scrollTop = 0;
          scrollBar.ps.update();
        });
      }
    }
  }
};
</script>

<style>
#listItemHeight {
  min-height: 5px !important;
  height: 20px;
}
.titleHighlight {
  background-color: lightblue !important;
  text: center;
  padding: 4px 4px 4px 0px !important;
}
.listHeightShort {
  height: 10px;
}
.listHeightLong {
  height: 150px;
}
</style>
